import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpContext, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AuthResponse} from '../models/auth-response';
import {ENV_CONFIG} from '../../environment.config';
import {SsoProvider} from '../../../../../../src/app/enums/sso-provider.enum';
import CustomHttpUrlEncodingCodec from '../../../../../../src/app/api-client/http-url-custom-encoder';
import {LsAuthResponse} from '../models/ls-auth-response';
import {UserResponseModel} from '../models/user-response-model';
import {UserUpdateModel} from '../models/user-update-model';
import {NgHttpCachingHeaderModel} from '../ng-http-caching/models/ng-http-caching-header.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpClient = inject(HttpClient);
  private environmentConfig = inject(ENV_CONFIG);

  constructor() { }

  authenticate(
    customerId: number,
    username: string,
    password: string,
    httpContext: HttpContext,
    ngHttpCachingHeaders: NgHttpCachingHeaderModel = null): Observable<AuthResponse> {
    let headers = new HttpHeaders().append('Content-Type', 'application/json');

    if (ngHttpCachingHeaders) {
      headers = Object.keys(ngHttpCachingHeaders)
        .reduce((acc, header) => acc.append(header, ngHttpCachingHeaders[header]), headers)
    }

    let requestBody;
    // Set up request
    if (customerId) {
      requestBody = {
        customerId: customerId,
        username: username,
        password: password,
      }
    } else {
      requestBody = {
        username: username,
        password: password,
      }
    }

    return this.httpClient.post(`${this.environmentConfig.idsApiUrl}Authenticate`, requestBody, {headers, observe: 'response', context: httpContext}).pipe(
      map((response: HttpResponse<any>) => {
        return response.body as AuthResponse;
      })
    )
  }

  authenticateViaSSO(token: string, ssoProvider: SsoProvider): Observable<AuthResponse> {
    let headers = new HttpHeaders().append('Content-Type', 'application/json')

    return this.httpClient.post(`${this.environmentConfig.idsApiUrl}SsoAuthenticate`, {token, ssoProvider}, {headers, observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response.body as AuthResponse;
      }),
    )
  }

  authenticateLS(token: string, username: string = null, password: string = null, context: HttpContext = null) {
    let headers = new HttpHeaders().append('Content-Type', 'application/X-www-form-urlencoded')
      .append('loginOnly', 'true')
      .append('html', 'true');

    if (token) {
      headers = headers.set('IdentityToken', token);
    }

    let requestBody = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()})
      .set('grant_type', 'password')
      .set('clientVersion', this.environmentConfig.version)

    if (username) {
      requestBody = requestBody.set('username', username);
    }

    if (password) {
      requestBody = requestBody.set('password', password);
    }

    return this.httpClient.post(`${this.environmentConfig.lsApiUrl}Authenticate`, requestBody, {headers, observe: 'response', context}).pipe(
      map((response: HttpResponse<any>) => {
        return response.body as LsAuthResponse;
      }),
    )
  }

  authenticateMP(token: string, context: HttpContext = null) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/X-www-form-urlencoded')
      .append('Authorization', `Bearer ${token}`);

    return this.httpClient.post(`${this.environmentConfig.mpApiUrl}Authenticate`, null, {headers, observe: 'response', context}).pipe(
      map((response: HttpResponse<any>) => {
        return response.body as AuthResponse;
      }),
    );
  }

  getUserInfo(token: string) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', `bearer ${token}`);
    return this.httpClient.get(`${this.environmentConfig.mpApiUrl}users/Me`, {headers, observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response.body as UserResponseModel;
      }),
    )
  }

  logoutMPManagerUser(token: string) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', `bearer ${token}`);
    return this.httpClient.post(`${this.environmentConfig.mpApiUrl}v1/internal/users/manager/logout`, null, {headers, observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      }),
    )
  }

  forgotPassword(email: string) {
    let headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.httpClient.post(`${this.environmentConfig.mpApiUrl}v1/internal/users/forgotpassword`, `"${email}"`, {headers, observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response.body as AuthResponse;
      }),
    )
  }

  sendConfirmation(email: string) {
    let headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.httpClient.post(`${this.environmentConfig.mpApiUrl}v1/internal/users/sendconfirmationemail`, `"${email}"`, {headers, observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response.body as AuthResponse;
      }),
    )
  }

  resetPasswordWithToken(userId: number, newPassword: string, token: string) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', `bearer ${token}`);
    return this.httpClient.post(`${this.environmentConfig.mpApiUrl}v1/internal/users/${userId}/resetpassword`, `"${newPassword}"`, {headers, observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response.body as AuthResponse;
      }),
    )
  }

  confirmUserAccountWithToken(userId: number, request: UserUpdateModel, token: string) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', `bearer ${token}`);
    return this.httpClient.put(`${this.environmentConfig.mpApiUrl}v1/internal/users/${userId}/confirmaccount`, request, {headers, observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response.body as AuthResponse;
      }),
    )
  }

  postEulaAccepted(userId: number, token: string) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', `bearer ${token}`);
    return this.httpClient.post(`${this.environmentConfig.mpApiUrl}v1/internal/users/eulaaccepted`, `"${userId}"`, {headers, observe: 'response'}).pipe(
      map((response: HttpResponse<any>) => {
        return response.body as AuthResponse;
      }),
    )
  }


}
